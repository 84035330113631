body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
}

div {
  width: 100%;
  box-sizing: border-box;
}

#terminal {
  height: 48vh;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
  /* Adicionado para evitar que o texto suma no overflow */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  scrollbar-width: none;
  perspective: 800px;
}

#terminal div {
  transform: rotateX(2deg) rotateY(-2deg);
  /* Curvatura mais sutil */
  transform-origin: center;
}

input {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.2);
  border: none;
  outline: none;
  width: 100%;
  font-size: 1em;
  padding: 10px;
  box-sizing: border-box;
  transform: rotateX(2deg) rotateY(-2deg);
  /* Curvatura mais sutil */
  transform-origin: center;
}

.cover {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url(filtro.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  animation: tela 0.2s linear infinite;
}

@keyframes text {
  0% {
    padding-left: 1px;
  }

  25% {
    padding-left: 0px;
  }

  50% {
    padding-left: -1px;
  }

  75% {
    padding-left: 0px;
  }

  100% {
    padding-left: 1px;
  }
}

@keyframes foto {
  0% {
    padding-left: 1px;
    opacity: 0.6;
  }

  25% {
    padding-left: 0px;
    opacity: 0.55;
  }

  50% {
    padding-left: -1px;
    opacity: 0.6;
  }

  75% {
    padding-left: 0px;
    opacity: 0.55;
  }

  100% {
    padding-left: 1px;
    opacity: 0.58;
  }
}

@keyframes tela {
  0% {
    background-position-y: 100.3%;
    background-position-x: 50.3%;
  }

  100% {
    background-position-y: center;
    background-position-x: center;
  }
}

img {
  width: 50%;
  max-width: 200px;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  transform: rotateX(2deg) rotateY(-2deg);
  /* Curvatura mais sutil */
}

.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}

.crt::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
  transform: rotateX(2deg) rotateY(-2deg);
  /* Garante que o filtro CRT acompanhe a curvatura */
  transform-origin: center;
}

.crt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: textShadow 10s infinite;
  flex-direction: column;
  justify-content: center;
}

@keyframes flicker {
  0% {
    opacity: 0.27861;
  }

  5% {
    opacity: 0.34769;
  }

  10% {
    opacity: 0.23604;
  }

  15% {
    opacity: 0.90626;
  }

  20% {
    opacity: 0.18128;
  }

  25% {
    opacity: 0.83891;
  }

  30% {
    opacity: 0.65583;
  }

  35% {
    opacity: 0.67807;
  }

  40% {
    opacity: 0.26559;
  }

  45% {
    opacity: 0.84693;
  }

  50% {
    opacity: 0.96019;
  }

  55% {
    opacity: 0.08594;
  }

  60% {
    opacity: 0.20313;
  }

  65% {
    opacity: 0.71988;
  }

  70% {
    opacity: 0.53455;
  }

  75% {
    opacity: 0.37288;
  }

  80% {
    opacity: 0.71428;
  }

  85% {
    opacity: 0.70419;
  }

  90% {
    opacity: 0.7003;
  }

  95% {
    opacity: 0.36108;
  }

  100% {
    opacity: 0.24387;
  }
}

@keyframes textShadow {
  0% {
    text-shadow: 0.44px 0 1px rgba(3, 82, 0, 0.5), -0.44px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  5% {
    text-shadow: 2.79px 0 1px rgba(3, 82, 0, 0.5), -2.79px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  10% {
    text-shadow: 0.03px 0 1px rgba(3, 82, 0, 0.5), -0.03px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  15% {
    text-shadow: 0.40px 0 1px rgba(3, 82, 0, 0.5), -0.40px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  20% {
    text-shadow: 3.48px 0 1px rgba(3, 82, 0, 0.5), -1.48px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  25% {
    text-shadow: 1.61px 0 1px rgba(3, 82, 0, 0.5), -1.61px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  30% {
    text-shadow: 0.70px 0 1px rgba(3, 82, 0, 0.5), -0.70px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  35% {
    text-shadow: 3.90px 0 1px rgba(3, 82, 0, 0.5), -1.90px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  40% {
    text-shadow: 3.87px 0 1px rgba(3, 82, 0, 0.5), -1.87px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  45% {
    text-shadow: 2.23px 0 1px rgba(3, 82, 0, 0.5), -1.23px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  50% {
    text-shadow: 0.08px 0 1px rgba(3, 82, 0, 0.5), -0.08px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  55% {
    text-shadow: 2.38px 0 1px rgba(3, 82, 0, 0.5), -1.38px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  60% {
    text-shadow: 2.20px 0 1px rgba(3, 82, 0, 0.5), -1.20px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  65% {
    text-shadow: 2.86px 0 1px rgba(3, 82, 0, 0.5), -1.86px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  70% {
    text-shadow: 0.49px 0 1px rgba(3, 82, 0, 0.5), -0.49px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  75% {
    text-shadow: 1.89px 0 1px rgba(3, 82, 0, 0.5), -0.89px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  80% {
    text-shadow: 0.08px 0 1px rgba(3, 82, 0, 0.5), -0.08px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  85% {
    text-shadow: 0.10px 0 1px rgba(3, 82, 0, 0.5), -0.10px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  90% {
    text-shadow: 3.44px 0 1px rgba(3, 82, 0, 0.5), -1.44px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  95% {
    text-shadow: 2.18px 0 1px rgba(3, 82, 0, 0.5), -1.18px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }

  100% {
    text-shadow: 2.62px 0 1px rgba(3, 82, 0, 0.5), -1.62px 0 1px rgba(0, 187, 56, 0.3), 0 0 3px;
  }
}

.click {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  animation: textShadow 1.6s infinite;
  font-family: monospace;
}

.env-inp {
  display: flex;
  align-items: center;
}

#terminal {
  animation: text 0.01s linear infinite
}


.dog {
  margin-top: -40px;
  animation: foto 0.2s linear infinite;
}

img {
  opacity: 0.5;
}




.text {
  animation: text 0.2s linear infinite;
}

@media (max-width: 768px) {
  img {
    width: 80%;
    max-width: 150px;
  }

  input {
    font-size: 0.9em;
  }

  div {
    padding: 5px;
  }

  #terminal,
  img,
  input {
    transform: rotateX(1deg) rotateY(-1deg);
    /* Ajusta a curvatura para telas menores */
  }
}

@media (max-width: 480px) {
  img {
    width: 100%;
    max-width: 120px;
  }

  input {
    font-size: 0.8em;
  }

  #terminal,
  img,
  input {
    transform: rotateX(0.5deg) rotateY(-0.5deg);
    /* Ajuste adicional da curvatura para dispositivos muito pequenos */
  }
}


a {
  color: rgba(255, 255, 255, 0.2);
}

a:hover {
  color: rgb(94, 0, 0);
}

.click p {
  animation: foto 1s linear infinite
}

/* App.css */

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 5px solid transparent; /* Cinza claro */
  border-top: 5px solid rgba(51, 255, 0, 0.2); /* Azul */
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
